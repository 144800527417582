$matrix-positive-high:     map-get($negative-colors, 2);
$matrix-positive-moderate: map-get($negative-colors, 5);
$matrix-neutral:		   $gray-base-95;
$matrix-negative-moderate: map-get($positive-colors, 5);
$matrix-negative-high: 	   map-get($positive-colors, 2);

.matrix {

	.bg-positive-high {
		background-color: $matrix-positive-high;
		border-color: $matrix-positive-high;
	}
	.bg-positive-moderate {
		background-color: $matrix-positive-moderate;
		border-color: $matrix-positive-moderate;
	}
	.bg-neutral {
		background-color:$matrix-neutral;
		border-color:$matrix-neutral;
	}
	.bg-negative-moderate {
		background-color: $matrix-negative-moderate;
		border-color: $matrix-negative-moderate;
	}
	.bg-negative-high {
		background-color: $matrix-negative-high;
		border-color: $matrix-negative-high;
	}
}

.matrix-wrap {
	display: grid;
	grid-template-columns: auto 1fr;

	@include media-breakpoint-up(md) {
		grid-template-columns: auto auto 1fr;
	}
}

.matrix-area {
	display: flex;
	flex-direction: column;
	min-width: fit-content;
}

.matrix-labels {
	display: flex;
}

.matrix-labels-y {
	flex: none;
	flex-direction: column;
	padding-top: 60px;

	.matrix-label {
		flex: 1;
		display: flex;
		align-items: center;
		min-height: 26px;
	}
}

.matrix-labels-x {
	flex: none;
	height: 60px;
	min-width: fit-content;

	.matrix-label {
		position: relative;
		min-width: 26px;

		span {
			position: absolute;
			top: 100%;
			left: 0;
			transform: rotate(-45deg) translate(10px, -50%);
			transform-origin: top left;
		}
	}
}

.matrix-label {
	flex: 1;
	
	span {
		display: block;
		max-width: 100px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 12px;
		line-height: 10px;
    	overflow-wrap: break-word;
	}
}

.matrix-row {
	display: flex;
	flex: 1;
}

.matrix-element {
	position: relative;
	flex: 1;
	min-width: 26px;
	min-height: 26px;
	max-height: 300px;
	border: 1px solid $gray-base-90;
	background-color: $panel-background;
	&:before {
		position: absolute;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;
		display: block;
		border: 1px solid transparent;
		content: "";
	}
	&:hover {
		&:before {
			border-color: $yc-blue-ada;
		}
	}
	&[aria-describedby] {
		&:before {
			background-color: rgba($yc-blue-ada, .42);
		}
	}
}

.matrix-element-value {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.matrix-legend {
	grid-column: 1 / -1;
	@include media-breakpoint-down(sm) {
		// reset padding defined by js
		padding-top: 20px !important; 
	}
	@include media-breakpoint-up(md) {
		grid-column: auto;
		padding-right: 32px;
	}
}

.matrix-legend-labels {
	display: flex;
	@include media-breakpoint-up(md) {
		display: block;
	}
}

.matrix-legend-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 12px;
	&:not(:last-child) {
		padding-right: 12px;
	}
	@include media-breakpoint-up(md) {
		flex-direction: row;
		&:not(:last-child) {
			border-bottom: 1px solid $gray-base-90;
			padding-right: 0;
		}
	}
}

.matrix-legend-value {
	margin-top: 4px;
	margin-left: 18px;
	@include media-breakpoint-up(md) {
		margin-top: 0;
		margin-left: 4px;
	}
}
